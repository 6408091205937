import React from 'react';
import { Helmet } from 'react-helmet';

import BlogHeader from '../components/BlogHeader';
import BlogCard from '../components/BlogCard';
import Favicon from '../images/favicon.ico';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';

export const query = graphql`
  query QueryBlogCoverImages {
    posts: allGhostPost(
      filter: { tags: { elemMatch: { name: { eq: "superserrurier" } } } }
    ) {
      nodes {
        slug
        title
        custom_excerpt
        feature_image_sharp {
          childImageSharp {
            fixed(width: 515) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={Favicon} />
        <meta charSet="utf-8" />
        <title>
          A propos de Super Serrurier - le meilleur serrurier de Suisse
        </title>
        <meta
          name="description"
          content="Nos serruriers sont des experts certifiés dans plusieurs domaines et vous offrent un excellent conseil."
        />
      </Helmet>

      <BlogHeader />
      <div className="py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-12 mx-auto">
          <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {data.posts.nodes.map((post) => {
              return (
                <BlogCard
                  cardImg={post.feature_image_sharp.childImageSharp.fixed}
                  type="Article"
                  title={post.title}
                  shortText={post.custom_excerpt}
                  date="Mar 16, 2020"
                  blogUrl={post.slug}
                  full
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default BlogPage;

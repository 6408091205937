import React from "react";

export default function () {
  return (
    <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-textcolor sm:text-5xl md:text-6xl">
          <span className="block xl:inline">Bienvenue sur le blog de</span>{" "}
          <br />
          <span className="block text-primarycolor xl:inline">
            Super serrurier
          </span>
        </h1>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Témoignages de clients, conseils et opinions sur tout ce qui touche de
          près ou de loin à la serrurerie, le tout au même endroit.
        </p>
      </div>
    </main>
  );
}
